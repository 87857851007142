import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useSessionContext } from 'contexts/session';

export const useUser = ({ skip = false } = {}) => {
  const { userId } = useSessionContext();
  const { data, error, loading, refetch } = useQuery<
    GetUserQuery,
    GetUserQueryVariables
  >(GET_USER_QUERY, {
    skip,
    variables: { userId },
  });

  return { data: data?.user, error, loading, refetch };
};

export interface UserData {
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  languageShortCode: string;
  communicationPreferences: Array<string | null> | null;
  preferences: {
    enabledGoogleSignIn: boolean;
  };
  zipCode: {
    id: number;
    zip: string;
    city: string | null;
  };
  lmiProofDocs: Array<{
    proofDocUrls: Array<string | null>;
    proofDocName: string | null;
    proofDocStatus: string | null;
    proofDocUploadedAt: string | null;
    uaLmiSourceTypeId: number | null;
    utilityAccountId: number | null;
    utilityName: string | null;
    utilityAccountNumber: string | null;
    utilityServiceAddress: string | null;
  } | null>;
  originationSource: {
    id: number;
    funnel: string | null;
    originationSourceType: string;
    enrollmentExperience: string | null;
  } | null;
  userDocuSignEnvelopes: Array<{
    displayName: string | null;
    contractType: string | null;
    status: string | null;
    completedFile: string | null;
    envelopeUpdatedAt: string | null;
    userId: string | null;
    personalizedContractId: string | null;
  } | null> | null;
}

export const GET_USER_QUERY = gql`
  query GetUser($userId: Int!) {
    user(id: $userId) {
      id
      name
      firstName
      lastName
      email
      preferences {
        enabledGoogleSignIn
      }
      languageShortCode
      communicationPreferences
      zipCode {
        id
        zip
        city
      }
      lmiProofDocs {
        proofDocUrls
        proofDocName
        proofDocStatus
        proofDocUploadedAt
        uaLmiSourceTypeId
        utilityAccountId
        utilityName
        utilityAccountNumber
        utilityServiceAddress
      }
      originationSource {
        id
        funnel
        originationSourceType
        enrollmentExperience
      }
      userDocuSignEnvelopes {
        displayName
        contractType
        status
        completedFile
        envelopeUpdatedAt
        userId
        personalizedContractId
      }
    }
  }
`;

export const getUserMock = {
  user: {
    email: 'larry.lovebird@windmills.com',
    firstName: 'Larry',
    id: 10,
    lastName: 'Lovebird',
    name: 'Larry Lovebird',
    preferences: {
      enabledGoogleSignIn: true,
    },
    languageShortCode: 'en',
    communicationPreferences: ['email', 'transactional_sms'],
    lmiProofDocs: [
      {
        proofDocUrls: ['https://arcadia.com/lmi-proof-docs/1'],
        proofDocName: 'LMI Proof Doc 1',
        proofDocStatus: 'pending_review',
        proofDocUploadedAt: '01-01-2023',
        uaLmiSourceTypeId: 1,
        uaLmiSourceId: 1,
        utilityName: 'Arcadia Power',
        utilityAccountNumber: '1234567890',
        utilityServiceAddress: '123 Main St, Anytown, USA',
      },
    ],
    originationSource: {
      id: 1,
      funnel: 'solar',
      enrollmentExperience: 'utility_connect',
    },
    userDocuSignEnvelopes: [
      {
        displayName: 'Subscriber Verification of Low to Moderate Income Status',
        contractType: 'lmi',
        status: 'completed',
        completedFile: null,
        envelopeUpdatedAt: '2024-01-29',
        userId: '2566292',
        personalizedContractId: '172084',
      },
      {
        displayName: 'Subscriber Verification of Low to Moderate Income Status',
        contractType: 'lmi',
        status: 'sent',
        completedFile: null,
        envelopeUpdatedAt: '2024-01-10',
        userId: '2566292',
        personalizedContractId: '172084',
      },
      {
        displayName: 'Subscriber Verification of Low to Moderate Income Status',
        contractType: 'lmi',
        status: 'sent',
        completedFile: null,
        envelopeUpdatedAt: '2024-02-10',
        userId: '2566292',
        personalizedContractId: '172084',
      },
    ],
  },
};

export const useUserMock = {
  data: {
    ...getUserMock.user,
  },
  error: undefined,
  loading: false,
};
