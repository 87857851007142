export const settings = {
  title: 'Settings',
  accountDetails: {
    title: 'Account details',
    accountNumber: {
      label: 'Arcadia account',
      copy: '#%{accountNumber}',
    },
    nickname: {
      label: 'Account nickname',
      title: 'Account nickname',
      button: 'Update account nickname',
      toastNotification: 'Updated account nickname',
    },
  },
  personalInformation: {
    title: 'Personal information',
    nameLabel: 'Name',
    editLoginLabel: 'Arcadia login',
    smsPreference: {
      label: 'SMS Preferences',
      subHeader: 'Receive marketing and transactional messages',
    },
    googleSignIn: {
      label: 'Google sign-in',
      enabled: 'Enabled',
    },
    buttons: {
      ccpa: 'Don’t sell my personal information',
    },
  },
  checkLmiEligibility: {
    title: 'Low-to-moderate household eligibility',
    description:
      'Participation in select assistance programs can help you get enrolled on a solar farm faster. We’ll review your documentation and once approved you’ll be on your way to solar savings.',
    descriptionIra:
      'Participation in select assistance programs can help you get enrolled on a solar farm faster and/or see additional community solar savings. Please upload a photo of a card, letter, utility bill, or screenshot from your program portal that proves your active participation in one of the qualifying programs (supported formats: PNG, JPEG, HEIC).',
    DocumentInformation: {
      title: 'Your document must include the following information:',
      programName: 'Qualifying program name',
      participantName: 'Participant name',
      date: 'Issue date or expiration date',
      subContext:
        'If your document is missing any of this information, please upload an additional document that includes the missing information.',
      additonalInfo:
        'If you upload an additional document, ensure that either the participant name or an ID number are present on both documents.',
    },
    eligibilityDocuments: {
      title: 'Eligibility documents',
      default:
        'No documents have been uploaded. Click the ‘Add document’ button to see a list of accepted assistance programs.',
      button: 'Add document',
      imageInfo: 'Supported image formats: PNG, JPEG, HEIC (Max file size 5MB)',
    },
    addDocument: {
      title: 'Add new document',
      utilityDropdown: 'Select utility account',
      utilityInfo:
        'This utility account should match the service address on the eligibility documents you provide.',
      programDropdown: 'Choose program',
      saveDocumentButton: 'Save document(s)',
      cancelDocumentButton: 'Cancel',
      successToast: 'Eligibility document uploaded',
      failureToast:
        'Something went wrong. Please upload the eligibility documents again.',
      notRequiredForUtility:
        'Eligibility documents are not required for this utility',
      disableSubmit:
        'The selected utility account already has a document of the same type under review.',
      checkBox:
        'By checking this box, I hereby affirm that the documentation I have provided is true and accurate to the best of my knowledge, is currently effective and/or has been effective within the past twelve (12) months, and corresponds with my own enrollment and participation, or the enrollment and participation of another member of my household, in the income-qualifying assistance program identified therein.',
    },
    selfAttestation: {
      title: 'Self-attestation',
      description:
        'Confirm that you are a low-to-moderate income household by completing a self-attestation form.',
      default: 'No signed attestation forms',
      button: 'Request self-attestation form',
      error: 'Could not load self-attestation forms',
      tryAgain: 'Try again',
      lastEnvelope:
        'You requested a new self-attestation form on %{lastSentDate}. Please check your email account, including your ‘Spam’ or ‘Junk’ folder.',
    },
    disclosure: {
      title: 'Disclosure from',
      description:
        'Complete the disclosure form to be eligible to be put on a solar farm.',
      button: 'Request disclosure form',
      tryAgain: 'Try again',
    },
    selfAttestationModal: {
      title: 'Request new self-attestation form?',
      description:
        'You have already signed a self-attestation form. Please only request a new form if you have been asked to by Arcadia, or if you made a mistake on the previous form.',
      firstRequestDescription:
        'Confirm that you are a low-to-moderate income household by completing a self-attestation form.',
      sendButton: 'Request new self-attestation form',
      cancelButton: 'Cancel',
      toastNotificationSuccess:
        'A new self-attestation form has been emailed to you.',
      toastNotificationError:
        'An error occurred while processing your request. Please try again later.',
      utilityDropdown: 'Select utility account',
      notRequiredForUtility:
        'Self-attestation form is not required for this utility',
    },
    disclosureModal: {
      title: 'Request new %{formName} form?',
      description:
        'You have already signed a %{formName} form. Please only request a new form if you have been asked to by Arcadia, or if you made a mistake on the previous form.',
      firstRequestDescription:
        'A completed %{formName} form is required for placement on a solar farm.',
      sendButton: 'Request new %{formName} form',
      cancelButton: 'Cancel',
      toastNotificationSuccess:
        'A new %{formName} form has been emailed to you.',
      toastNotificationError:
        'An error occurred while processing your request. Please try again later.',
      utilityDropdown: 'Select utility account',
      notRequiredForUtility: '%{formName} is not required for this utility',
      lastEnvelope:
        'You have already requested a new %{formName} form on %{lastSentDate}. Please check your email account, including your ‘Spam’ or ‘Junk’ folder.',
    },
  },
  smsPreference: {
    title: 'SMS Preference',
    updateButton: 'Update SMS Preference',
    marketing: {
      header: 'Marketing messages',
      subHeader:
        'Receive the latest information about Community solar and products from Arcadia',
      updateNotification: 'SMS Preference updated!',
      updateFailed: 'Something went wrong. Please try again.',
    },
  },
  contractAgreements: {
    title: 'Legal agreements',
    signedOn: 'Signed on %{acceptedDate}',
    download: 'Download %{agreementName}',
    incomplete: 'Incomplete',
    completedAgreements: 'Completed',
    signTerms: 'Sign incomplete terms',
    openSignTerms: 'Sign Terms',
  },
  editLogin: {
    title: 'Arcadia login',
    labels: {
      email: 'Email',
      password: 'New password',
    },
    buttons: {
      update: 'Update login',
    },
    updateNotification: 'Updated!',
  },
  name: {
    title: 'Name',
    labels: {
      firstName: 'First name',
      lastName: 'Last name',
    },
    buttons: {
      update: 'Update name',
    },
    errors: {
      firstNameError: 'Invalid first name',
      lastNameError: 'Invalid last name',
    },
    updateNotification: 'Updated!',
  },
  utilitySync: {
    title: 'Utility Sync',
    subtitle: 'Utility Credentials',
    utilityLoginLabel: '<span class="notranslate">%{utilityName}</span> Login',
    copyAriaLabel: 'Copy utility login email',
    connectUtilityAriaLabel: 'Connect your utility account',
    warningIconLabel: 'Utility credential is incorrect',
    status: {
      correct: 'Connected',
      empty: 'Sync your utility account',
      incorrect: 'Invalid',
      unverified: 'Verifying',
    },
    warningText: 'Reconnect your utility',
    reconnectButton: 'Reconnect',
    connectUtilityButton: 'Enter utility login credentials',
    reconnectError:
      'Utility connect is not available. Please reload this page or try again after sometime.',
    reconnectAriaLabel: 'Reconnect utility credentials',
  },
  accountSettings: {
    title: 'Account settings',
    description:
      'These settings are specific to <span class="notranslate">%{accountName}</span>. To view settings for other accounts, switch to that account.',
    accountDetails: {
      heading: 'Account details',
      subheading: '<span class="notranslate">%{accountName}</span>',
    },
    planDetails: {
      heading: 'Plans',
      subheading: '%{numberOfActivePlans} active plans',
    },
    paymentMethods: {
      heading: 'Payment methods',
      numberOfMethods: {
        one: '1 payment method',
        notOne: '%{number} payment methods',
      },
      subheading: 'View your payment methods',
    },
    utilitySync: {
      heading: 'Utility sync',
      numberOfUtilities: {
        one: '1 utility',
        notOne: '%{number} utilities',
      },
      whaleCopy: 'Sync your utility account',
      warningText: 'Reconnect your utility.',
      warningIconLabel: 'Utility credential is incorrect',
    },
    checkLmiEligibility: {
      heading: 'Low-to-moderate household eligibility',
      copy: 'Manage your eligibility documents',
    },
    moving: {
      heading: 'Moving',
      copy: 'Start a move with us',
    },
  },
  userSettings: {
    title: 'User settings',
    description: 'These settings apply across all of your accounts.',
    personalInformation: {
      heading: 'Personal information',
      copy: '%{name}, email, password, SMS preferences',
    },
    contractAgreements: {
      heading: 'Legal agreements',
      subHeading: '%{numberOfContractAgreements} documents',
    },
    languagePreference: {
      heading: 'Language preferences',
      en: 'English',
      es: 'Español',
      subHeading: 'Select your preferred language',
      description:
        'The language you choose in the dropdown below, will be used for your Arcadia account and communications.',
      copy: '%{language}',
      button: 'Save',
      toast: 'Your preference has been updated!',
      label: 'Language',
      error:
        'Something went wrong. Email support@arcadia.com or call 866-526-0083, M-F, 9-5 PM EST for support.',
    },
    referrals: {
      heading: 'Refer friends',
      savingsCopy: 'Invite friends and save money',
      impactCopy: 'Invite your friends',
    },
    buttons: {
      logout: 'Sign out',
    },
  },
  settings: {
    title: 'Settings',
    accountDetails: {
      heading: 'Account details',
    },
    personalInformation: {
      heading: 'Personal information',
      copy: '%{name}, email, password, SMS preferences',
    },
    paymentMethods: {
      heading: 'Payment methods',
      numberOfMethods: {
        one: '1 payment method',
        notOne: '%{number} payment methods',
      },
      subheading: 'View your payment methods',
    },
    utilitySync: {
      heading: 'Utility sync',
      numberOfUtilities: {
        one: '1 utility',
        notOne: '%{number} utilities',
      },
      whaleCopy: 'Sync your utility account',
    },
    contractAgreements: {
      heading: 'Legal agreements',
      subHeading: '%{numberOfContractAgreements} documents',
    },
    membership: {
      heading: 'Arcadia membership',
      active: 'Active',
      pending: 'Pending',
    },
    moving: {
      heading: 'Moving',
      copy: 'Start a move with us',
    },
    referrals: {
      heading: 'Refer friends',
      savingsCopy: 'Invite friends and save money',
      impactCopy: 'Invite your friends',
    },
    buttons: {
      logout: 'Sign out',
    },
  },
  membership: {
    title: 'Plans',
    content: {
      home: 'My home',
      homeAddress: 'Home address',
      noAddressAvailable: 'No address available',
      nickname: 'Nickname',
      accountLabel: 'Arcadia account',
      accountNumber: '#%{accountNumber}',
      autoPay: {
        title: 'Auto-pay',
        active: 'On',
        inactive: 'Off',
      },
    },
    nickname: {
      title: 'Plan nickname',
      label: 'Plan nickname',
      button: 'Update plan nickname',
      toastNotification: 'Updated plan nickname',
    },
    tabs: {
      active: 'Active plans',
      inactive: 'Inactive plans',
      noPlansToShow: 'You have no %{type} plans',
    },
    reactivateButton: 'Reactivate plan',
    activePlans: {
      smbSubtitle:
        '<span class="notranslate">%{utilityDisplayName} %{utilityAccountNumber} %{address}</span>',
    },
  },
  utilitySyncCredential: {
    title: 'Utility credential',
    credentialDetails: 'Utility credential details',
    utilityCompany: {
      title: 'Utility company',
      link: 'Visit utility site',
    },
    utilityAccount: {
      title: 'Utility account%{utilityAccountNumber}',
      serviceAddressLabel: 'Service address',
      noServiceAddress: 'Service address unavailable',
      accountNumberLabel:
        '<span class="notranslate">%{displayName}</span> account',
      noAccountNumber: 'Account number unavailable',
    },
    utilityAccounts: {
      title: 'Utility accounts',
      accountNumberLabel: 'Utility account%{utilityAccountNumber}',
    },
  },
  utilitySyncEdit: {
    title: '<span class="notranslate">%{utilityName}</span> login',
    backLink: {
      location: 'utility credential page',
    },
    description:
      'Utility sync ensures you’re getting clean and renewable electricity at home.',
    error: {
      title: 'Utility sync',
    },
    ameren: {
      description:
        'Please provide account access by signing in through the Ameren portal.',
      descriptionSynced:
        'If you need to resync your Ameren account, sign in below through the Ameren portal.',
      buttons: {
        sync: 'Sync Ameren',
        resync: 'Resync Ameren',
      },
    },
    utilityCredential: {
      disclaimer:
        'These credentials need to match what <span class="notranslate">%{utilityName}</span> has on file. Changing them here will not update what <span class="notranslate">%{utilityName}</span> has on file.',
      utilityUsernameLabel:
        '<span class="notranslate">%{utilityName}</span> username',
      utilityPasswordLabel:
        '<span class="notranslate">%{utilityName}</span> password',
      buttons: {
        update: 'Sync <span class="notranslate">%{utilityName}</span>',
      },
    },
    accountNumber: {
      disclaimer:
        'These credentials need to match what <span class="notranslate">%{utilityName}</span> has on file. Changing them here will not update what <span class="notranslate">%{utilityName}</span> has on file.',
      utilityAccountNumberLabel:
        '<span class="notranslate">%{utilityName}</span> account number',
      utilityAccountNumberConfirmLabel:
        'Confirm <span class="notranslate">%{utilityName}</span> account number',
      errors: {
        doNotMatch: 'Account numbers do not match.',
        conEdLength: 'Account number must be 15 digits long.',
      },
      buttons: {
        update: 'Sync <span class="notranslate">%{utilityName}</span>',
      },
    },
  },
  paymentInformation: {
    title: 'Payment information',
    subtitle: 'Viewing: Account #%{accountNumber}',
    tabs: {
      planMethods: 'Settings',
      accountMethods: 'Wallet',
      ariaLabel: 'Payment methods',
    },
    paymentMethods: {
      addPaymentMethod: 'Add payment method',
      creditCard: {
        label: '%{description}',
        description: 'Expires: %{date}',
      },
      ach: {
        label: '%{description}',
        description: 'Checking',
      },
      cashapp: {
        label: 'Cash App Pay',
        description: 'Digital wallet',
      },
      other: {
        label: '%{description}',
        description: '',
      },
      changePaymentMethod: 'Change payment method',
      errors: {
        transactionError:
          'Your payment method %{description} was declined. Your outstanding balance is $%{total}. Please update the payment method for this plan; once updated, it will be used in our next attempt to charge this balance.',
        noPaymentMethod:
          'This plan does not have an assigned payment method. Please add a payment method so we can process energy statements for this plan.',
        ariaLabel: 'Error icon',
        declineError: {
          long: 'There was an error with this payment method',
          short: 'Declined',
        },
        expiredPaymentMethod: {
          long: 'Payment method expired',
          short: 'Expired',
        },
        expiringPaymentMethod: {
          long: 'Payment method expiring soon',
          short: 'Expiring soon',
        },
      },
    },
    addPaymentMethodModal: {
      screens: {
        addPaymentMethod: {
          title: 'Add payment method',
          subtitle:
            'You are adding a payment method. Once added, it will be used for any outstanding and future charges on plans it is assigned to.',
          buttons: {
            addAndContinue: 'Add and continue',
          },
          paymentHoldCallout:
            "If you add a credit card, we'll place a temporary hold of $1 on your card. Don't worry - this is not a charge and will be released within 24 hours. Depending on your bank, it could take up to 7 days for your funds to be available.",
        },
        setPaymentMethod: {
          title: 'Set payment preferences',
          subtitle: 'Set as preferred payment method*',
          note: '*Note: This payment method has been added to your wallet, but will not be used unless set as preferred payment method on a plan.',
          buttons: {
            submit: 'Save changes',
          },
        },
        confirmation: {
          paymentAdded: {
            title: 'Your payment method has been added to your wallet',
            description:
              'Your payment method has been added. You will receive a confirmation email in a few minutes. Visit Payment information > Settings to use this payment method for your Arcadia statements.',
          },
          paymentSet: {
            title: 'Your payment method has been updated',
            descriptions: {
              default:
                'Your payment method is now your preferred payment method for the plans below. This card will be used for your existing and upcoming statements. You will receive a confirmation email in a few minutes.',
              whale:
                'Your payment method is now your preferred payment method for %{count} plans*. This card will be used for your existing and upcoming statements. You will receive a confirmation email in a few minutes.',
            },
            subheading: 'Plans',
            note: '*Note: You can view your plans and their preferred payment method in Payment information > Settings',
          },
          buttons: {
            submit: 'Got it',
            close: 'Close',
          },
        },
        error: {
          generic: [
            'Oops. Something went wrong.',
            'Please close the modal and try again.',
          ],
          setPaymentMethod: [
            'Oops. We were unable to assign your new payment method on your plans.',
            'Please close the modal and assign it to plans in the settings tab.',
          ],
          paymentHold: [
            'Your payment method is invalid.',
            'Please close the modal and add another payment method.',
          ],
        },
      },
    },
    deletePaymentMethod: {
      title: 'Remove payment method',
      subTitle: 'Are you sure you want to remove this payment method?',
      subTitleAlt:
        'Sorry, this method can’t be removed yet because it’s currently in use. To delete it, add and switch to a different payment method, or contact us if you need help.',
      buttons: {
        cancel: 'Cancel',
        close: 'Close',
        remove: 'Remove',
      },
      notification: 'Payment method removed',
    },
    planMethods: {
      title: 'Payment preferences',
      subtitle: 'Change your plan payment settings and preferences',
      planSubtitle: 'Preferred payment method',
      ariaButtonLabel: '%{action} plan payment details',
    },
    accountMethods: {
      title: 'Payment methods',
      subtitle: 'Add and manage your stored payment methods',
      ariaLabels: {
        edit: 'Edit %{description}',
      },
    },
    paymentMethodPage: {
      buttons: {
        save: 'Save changes',
        remove: 'Remove',
      },
      notification: 'Payment method updated',
      planContent: {
        assignedPlansLabel: 'Preferred payment method for:',
        unassignedPlansLabel: 'Set as preferred payment method',
        whaleAssignedPlans: {
          text: '%{whaleAssignedPlansCount} of your Arcadia statements',
          all: 'All',
        },
      },
    },
    planToggles: {
      allPlans: 'For all my Arcadia statements',
    },
    updatePlanMethod: {
      title: 'Change payment method',
      subTitle:
        'Select below which saved payment method you prefer for %{planDisplayName}',
      buttons: {
        save: 'Save changes',
        addNewMethod: 'Add a payment method',
      },
      notification: 'Plan payment method updated',
    },
  },
  nickname: {
    title: 'Nickname',
    label: 'Nickname',
    button: 'Update nickname',
    toastNotification: 'Updated nickname',
  },
  moving: {
    link: "I'm moving",
    selectAccount: {
      title: 'Select your previous address',
      description:
        'Take 5 minutes to move with us, and we’ll update your information with Arcadia.',
      accountDropdown: {
        label: 'Previous address',
        accountName: {
          closed: '%{accountName} (inactive)',
          active: '%{accountName}',
          utilityAccountNumber:
            '%{utilityName} utility account%{accountNumber}',
        },
      },
      nextButton: 'Continue',
      disclaimer:
        'Please be aware that you need to notify your utility to start service at your new location.',
      accountsLoadingError:
        'There was an error loading your accounts. Please try again or contact support@arcadia.com.',
    },
    addAccountDetails: {
      title: 'Tell us about where you’ve moved',
      description: 'We’ll use these details to update your Arcadia account.',
      zipCodeInput: {
        label: 'Zip code',
        placeholder: 'Please enter your zip code.',
      },
      utilityDropdown: {
        label: 'Utility',
      },
      nicknameInput: {
        label: 'Nickname (optional)',
        placeholder: 'Please enter a nickname.',
        helperText:
          'Adding a nickname helps make your account easily recognizable',
      },
      nextButton: 'Continue',
    },
    addUtilityServiceDetails: {
      title: 'Now tell us about your new utility service',
      serviceSet: {
        legend: 'Have you set up your new home’s utility service yet?',
        yes: 'Yes, I have set up service',
        no: 'No, I still need to set up service',
      },
      serviceOnline: {
        legend:
          'Is your new home on your online %{utilityName} utility account?',
        explanatoryText:
          'We can’t add your new home to your Arcadia account until it’s on your online utility account. ',
        linkText: 'Learn more.',
        yes: 'Yes, my new home is on my online utility account',
        no: 'No, my new home is not on my online utility account',
      },
      nextButton: 'Continue',
    },
    movingExplained: {
      title: 'Moving, explained',
      firstParagraph:
        'It’s easy to move with Arcadia and bring your clean energy impact to your new home.',
      subHeaders: [
        'First, set up your new utility service',
        'Next, confirm your home is on your online utility account',
      ],
      paragraphs: [
        'Contact your utility to let them know when to stop service at your old address and start service at your new home.',
        'Some utilities automatically add your new home to an online utility account. If your new home is not on your online utility account or you are not sure, our Energy Advisors can help you set it up. We need your online account to be set up before we can add your new home to your Arcadia account.',
      ],
    },
    addPaymentMethod: {
      title: 'Good news! You can join community solar with your new home',
      description:
        'With Arcadia, you’ll never be charged extra to participate in community solar. But we do need you to set up auto-payment for your <span class="notranslate">%{utility}</span> bills through our secure platform so we can directly apply your monthly savings.',
    },
    reviewNewHome: {
      title: 'Review your changes',
      description:
        'You’ve updated your zip code and utility details. Please confirm that everything looks correct:',
      previousAddress: 'Previous address',
      homeAddress: 'Home address',
      updatedInfo: 'Updated information',
      zipCode: 'Zip code',
      utility: 'Utility',
      newNickname: 'Nickname',
      nextButton: 'Confirm',
      createAccountError:
        'There was a problem creating your new plan. Please contact Support at support@arcadia.com',
      createPlanError:
        'There was a problem creating your new plan. Please contact Support at support@arcadia.com',
    },
    viewSuccess: {
      viewDashboardButton: 'View your dashboard',
      vnmToVnm: {
        title: 'Success! You’ve added your new home to Arcadia',
        closedContent: 'You can view your new home on your dashboard.',
        content: [
          'You can view your new home on your dashboard. We’ll deactivate your old home’s plan once your service ends.',
          'Your new utility also supports community solar, so you can join a local solar farm. Let’s see if you’re eligible to join our community solar program. ',
        ],
        learnMoreText: 'Learn more',
        button: 'Check community solar availability',
      },
      nonVnmToVnmConnected: {
        title: 'Success! You’ve added your new home to Arcadia',
        closedContent: 'You can view your new home on your dashboard.',
        content: [
          'You can view your new home on your dashboard. We’ll deactivate your old home’s plan once your service ends.',
          'Your new utility supports community solar, so you can join a local solar farm and save money on your power bill. Plus, we’ll waive your Arcadia membership fee.',
          'Let’s see if you’re eligible to join our community solar program. ',
        ],
        learnMoreText: 'Learn more',
        button: 'Check community solar availability',
      },
      nonVnmToVnmUnconnected: {
        title: 'Success! You’ve added your new home to Arcadia',
        closedContent: 'You can view your new home on your dashboard.',
        content: [
          'You can view your new home on your dashboard. We’ll deactivate your old home’s plan immediately.',
          'Your new utility supports community solar, so you can join a local solar farmand save money on your power bill. Plus, we’ll waive your Arcadia membership fee.',
          'Let’s see if you’re eligible to join our community solar program. ',
        ],
        learnMoreText: 'Learn more',
        button: 'Check community solar availability',
      },
      toNonVnmConnected: {
        title: 'Success! Your clean energy will move with you',
        closedContent: 'You can view your new home on your dashboard.',
        content: [
          'You can view your new home on your dashboard. We’ll deactivate your old home’s plan once your service ends.',
        ],
        button: 'Continue setting up home',
      },
      toNonVnmUnconnected: {
        title: 'Success! Your clean energy will move with you',
        closedContent: 'You can view your new home on your dashboard.',
        content: [
          'You can view your new home on your dashboard. We’ll deactivate your old home’s plan immediately.',
        ],
        button: 'Continue setting up home',
      },
    },
    connectUtility: {
      nonVnmTitle:
        'Community solar isn’t available at your new zip code yet, but we’re working on it!',
      nonVnmDescription:
        'Looks like you’re moving to <span class="notranslate">%{state}, where community solar isn’t available yet. We’ve added you to the waitlist! Meanwhile, connect your new utility credentials to be first in line for savings when it becomes available.',
      utilitySyncButtonText: 'Enter utility login credentials',
      skipButton: 'Skip for now',
    },
    setUpService: {
      title: 'We’ll add your home once you’ve set up service',
      paragraphs: [
        'You need to set up service before we can add your new home to your Arcadia dashboard.',
        'Set up your utility service with <span class="notranslate">%{utilityName}</span> now, or take care of it later.',
      ],
      utilityLinkText: 'Go to <span class="notranslate">%{utilityName}</span>',
      remindMeLater: 'Remind me later',
      postSetupText: 'I have set up service',
    },
    getSetupReminder: {
      title: 'Great! We’ll remind you',
      content: [
        'Look out for email reminders from us about setting up your new service.',
        'Once your new service is set up, we can continue creating your new home and connecting it to clean energy.',
      ],
      button: 'Back to dashboard',
    },
    addOnlineAccount: {
      title:
        'Log into your <span class="notranslate">%{utilityName}</span> portal to add your new home',
      bodyText:
        'Once your portal is set up, we can finish adding your new home to Arcadia.',
      copyHeader: 'Your utility username',
      link: 'Go to <span class="notranslate">%{utilityName}</span>',
      laterButton: 'I’ll set it up later',
    },
    getOnlineSetupReminder: {
      title: 'Great! We’ll remind you',
      content: [
        'Look out for email reminders from us about setting up your online utility account.',
        'Once your new home is on your online utility account, we’ll add it to your Arcadia dashboard.',
      ],
      button: 'Back to dashboard',
    },
    viewConfirmation: {
      title: 'That’s it! We’ll add your new home once your service is active.',
      content: [
        'Look out for an email about viewing your new home on your Arcadia dashboard. We’ll also deactivate your old home’s plan once your service ends.',
      ],
      contentVnm:
        'Once your new home is active, you’ll also be able to join a community solar project.',
      button: 'Back to dashboard',
    },
  },
  differentUsernameAccordion: {
    label: 'Why is my username different?',
    content: [
      'Some utilities require the username and billing email address to be the same. If this is the case, we must change the username on file to a unique Arcadia email.',
      "This ensures that our billing system receives your e-bill and can generate your statement. Your utility password doesn't change.",
    ],
  },
};
